<!--用户个人设置-->
<template>
  <div>
    <el-form ref="form" :model="form" label-width="80px" size="mini">
        <el-tabs :style="{height: docHeight+'px'}" class="my-tab">
            <el-tab-pane :label="msg('电子秤微调设置')" v-if="hasPermission('userSetting:scale')">
                <div class="row-wrap" >
                    <el-row v-for="(ec, idx) in form.ecsetting" :key="idx">
                        <el-col :xs="24" :sm="12" :md="6">
                            <el-form-item :label="msg('设备名')" >
                                <el-input v-model="ec.name" ></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="12" :md="6">
                            <el-form-item :label="msg('COM端口')">
                                <el-input v-model="ec.port"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24">
                            <el-form-item :label="msg('取值表达式')" label-width="120px">
                                <el-input v-model="ec.express" type="textarea" :rows="5"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24">
                        <el-form-item :label="msg('操作')">
                            <div>
                                <el-switch v-model="ec.disabled"
                                    inactive-text="是否激活" @click.native="setDefault(idx, 'ecsetting')"
                                    :active-value="1" :inactive-value="0"
                                    active-color="#13ce66" inactive-color="#ff4949">
                                </el-switch>
                                <el-link style="margin-left:20px;" icon="el-icon-delete" 
                                    type="danger" :underline="false"
                                    @click="form.ecsetting.splice(idx, 1);">{{msg('删除')}}</el-link>
                            </div>
                        </el-form-item>
                        </el-col>
                    </el-row>
                    <div style="text-align:center;">
                        <el-button type="primary" @click="addEc()" plain size="mini" >添加更多</el-button>
                    </div>
                    <el-alert title="取值表达式使用js代码， ${value}表示从电子秤读取到的数据">
                        <template>
                            <pre>
(function(){   
    var val = "${value}";
    val = val.split("=");
    val = val[val.length-2];
    return (val.split("").reverse().join(""))*1;
})(); 
                            </pre>
                        </template>
                    </el-alert>
                    <el-alert title="如果您不知道从电子秤读取到的数值是什么样子，请点击下面的按钮下载测试工具">
                        <template>
                        <a :href="comTest">点击下载</a>
                        </template>
                    </el-alert>
                    <!-- <div style="text-align:center; margin-top: 15px; margin-bottom:15px;">
                        <el-button type="primary" size="mini"  @click="update"><i class="el-icon-check"></i><span>{{msg('确定')}}</span></el-button>
                    </div> -->
                </div>
            </el-tab-pane>
            <el-tab-pane :label="msg('货单样式设置')" v-if="hasPermission('userSetting:ticket')">
                <div class="row-wrap" >
                    <span v-for="(ticketSetting, idx) in form.ticketSettings" :key="idx">
                        <div style="box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);padding:10px 10px;">
                            <el-row>
                                <el-col :xs="24" :sm="12" :md="6">
                                    <el-form-item :label="msg('设置名')" >
                                        <el-input v-model="ticketSetting.name" :placeholder="msg('请输入名称，方便选择')"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <div>
                                <el-checkbox
                                    border size="mini" class="pts-checkbox"
                                    v-model="ticketSetting.detail"  :label="msg('拣货单详情')" >
                                </el-checkbox>
                                <el-checkbox v-show="ticketSetting.detail" 
                                    border size="mini" class="pts-checkbox"
                                    v-model="ticketSetting.detailName"  :label="msg('拣货单货物品名')" >
                                </el-checkbox>
                                <el-checkbox v-show="ticketSetting.detail" 
                                    border size="mini" class="pts-checkbox"
                                    v-model="ticketSetting.detailCode"  :label="msg('拣货单货物编号')" >
                                </el-checkbox>
                                <el-checkbox v-show="ticketSetting.detail" 
                                    border size="mini" class="pts-checkbox"
                                    v-model="ticketSetting.detailShelfCode"  :label="msg('拣货单架位号')" >
                                </el-checkbox>
                                <el-checkbox v-show="ticketSetting.detail" 
                                    border size="mini" class="pts-checkbox"
                                    v-model="ticketSetting.detailTitle"  :label="msg('拣货单描述')" >
                                </el-checkbox>
                                <el-checkbox v-show="ticketSetting.detail" 
                                    border size="mini" class="pts-checkbox"
                                    v-model="ticketSetting.detailSpec"  :label="msg('拣货单规格&件数')" >
                                </el-checkbox>
                                <el-checkbox v-show="ticketSetting.detail" 
                                    border size="mini" class="pts-checkbox"
                                    v-model="ticketSetting.detailStoreType"  :label="msg('拣货单仓储类型')" >
                                </el-checkbox>
                                <el-checkbox
                                    border size="mini" class="pts-checkbox"
                                    v-model="ticketSetting.detailSku"  :label="msg('SKU&货号')" >
                                </el-checkbox>
                            </div>
                            <div>
                                <el-checkbox
                                    border size="mini" class="pts-checkbox"
                                    v-model="ticketSetting.detailPkg"  :label="msg('拣货单包号')" >
                                </el-checkbox>
                                <el-checkbox
                                    border size="mini" class="pts-checkbox"
                                    v-model="ticketSetting.detailDate"  :label="msg('拣货单日期')" >
                                </el-checkbox>
                                <el-checkbox
                                    border size="mini" class="pts-checkbox"
                                    v-model="ticketSetting.detailRemark1"  :label="msg('拣货单报单备注')" >
                                </el-checkbox>
                                <el-checkbox
                                    border size="mini" class="pts-checkbox"
                                    v-model="ticketSetting.detailRemark2"  :label="msg('拣货单货单备注')" >
                                </el-checkbox>
                                <el-checkbox
                                    border size="mini" class="pts-checkbox"
                                    v-model="ticketSetting.shopSellerMsg"  :label="msg('拣货单买家留言')" >
                                </el-checkbox>
                                <el-checkbox
                                    border size="mini" class="pts-checkbox"
                                    v-model="ticketSetting.pkgType"  :label="msg('拣货单打包類型')" >
                                </el-checkbox>
                                <el-checkbox
                                    border size="mini" class="pts-checkbox"
                                    v-model="ticketSetting.note"  :label="msg('拣货单订单备注')" >
                                </el-checkbox>
                                <el-checkbox
                                    border size="mini" class="pts-checkbox"
                                    v-model="ticketSetting.shopName"  :label="msg('拣货单店铺名')" >
                                </el-checkbox>
                                <el-checkbox
                                    border size="mini" class="pts-checkbox"
                                    v-model="ticketSetting.ownerName"  :label="msg('归属人')" >
                                </el-checkbox>
                                <el-checkbox
                                    border size="mini" class="pts-checkbox"
                                    v-model="ticketSetting.receiver"  :label="msg('收件人')" >
                                </el-checkbox>
                                <el-checkbox
                                    border size="mini" class="pts-checkbox"
                                    v-model="ticketSetting.expGoodType"  :label="msg('货物类型')" >
                                </el-checkbox>
                                <el-checkbox
                                    border size="mini" class="pts-checkbox"
                                    v-model="ticketSetting.orderPlatform"  :label="msg('订单卖场')" >
                                </el-checkbox>
                            </div>
                            <div>
                                <el-switch v-model="ticketSetting.disabled"
                                    inactive-text="是否默认" @click.native="setDefault(idx, 'ticketSettings')"
                                    :active-value="1" :inactive-value="0"
                                    active-color="#13ce66" inactive-color="#ff4949">
                                </el-switch>
                                <el-link style="margin-left:20px;" icon="el-icon-delete" 
                                    type="danger" :underline="false"
                                    @click="form.ticketSettings.splice(idx, 1);">{{msg('删除')}}</el-link>
                            </div>
                            <div class="page-tpl">
                                <template>
                                    <preview-tpl :ticketSetting="ticketSetting" ></preview-tpl>
                                </template>
                            </div>
                        </div>
                    </span>
                    <div style="text-align:center;">
                        <el-button type="primary" @click="addTs()" plain size="mini" >添加更多</el-button>
                    </div>
                    <!-- <div style="text-align:center; margin-top: 15px; margin-bottom:15px;">
                        <el-button type="primary" size="mini"  @click="update"><i class="el-icon-check"></i><span>{{msg('确定')}}</span></el-button>
                    </div> -->
                </div>
            </el-tab-pane>
            <el-tab-pane :label="msg('计重策略')" v-if="hasPermission('userSetting:weight')">
                <div class="row-wrap" >
                    <el-row v-for="(attach, idx) in form.attachWeights" :key="idx">
                        <el-col :xs="24" style="padding: 5px 0px;"> 
                            <el-input v-model="attach.xlabel" style="width:300px;" size="mini">
                                    <template slot="prepend">{{"设置名"}}</template>
                                </el-input>
                        </el-col>
                        <el-col :xs="24" :sm="10"  style="padding: 5px 0px;" > 
                            <el-radio-group v-model="attach.addedWeightType" size="mini">
                                <el-radio-button :label="0">{{msg('固定值')}}</el-radio-button>
                                <el-radio-button :label="1">{{msg('百分比值')}}</el-radio-button>
                            </el-radio-group>
                        </el-col>
                        <el-col :xs="24" :sm="14"  style="padding: 5px 0px;">
                            <div style="max-width: 300px;">
                                <el-input v-if="attach.addedWeightType===0" v-model="attach.addedWeight" 
                                    :placeholder="msg('固定增加重量')" size="mini">
                                        <!-- <template slot="prepend">{{"添加重量值"}}</template> -->
                                         <template slot="append">KG</template>
                                    </el-input>
                                <el-input v-else-if="attach.addedWeightType===1" v-model="attach.addedPercentWeight" 
                                    :placeholder="msg('按百分比增加重量')" size="mini">
                                        <!-- <template slot="prepend">{{"添加重量值"}}</template> -->
                                        <template slot="append">%</template>
                                </el-input>
                            </div>
                        </el-col>
                        <el-col :xs="24" :sm="24" :md="7" style="padding: 10px 5px">
                                <el-switch v-model="attach.disabled"
                                    inactive-text="是否启用" @click.native="setDefault(idx, 'attachWeights')"
                                    :active-value="1" :inactive-value="0"
                                    active-color="#13ce66" inactive-color="#ff4949">
                                </el-switch>
                                <el-link style="margin-left:20px;" icon="el-icon-delete" 
                                    type="danger" :underline="false"
                                    @click="form.attachWeights.splice(idx, 1);">{{msg('删除')}}</el-link>
                        </el-col>
                    </el-row>
                    <div style="text-align:center;">
                        <el-button type="primary" @click="addWeight()" plain size="mini" >添加更多</el-button>
                    </div>
                    <!-- <div style="text-align:center; margin-top: 15px; margin-bottom:15px;">
                        <el-button type="primary" size="mini"  @click="update"><i class="el-icon-check"></i><span>{{msg('确定')}}</span></el-button>
                    </div> -->
                </div>
            </el-tab-pane>
            <el-tab-pane :label="msg('材积计算')" v-if="hasPermission('userSetting:weight')">
                <div class="row-wrap" >
                    <el-row v-for="(volumn, idx) in form.volumns" :key="idx">
                        <el-col :xs="24" :sm="12" style="padding:15px;"> 
                            <el-link style="margin-top:8px;" type="danger" disabled>{{msg('注：输入长宽高尺寸按立方货形计算；只输入长宽尺寸为扁平货形计算；只输入长度为条、柱状货形计算；')}}</el-link>
                        </el-col>
                        <el-col :xs="24" style="padding:15px;"> 
                            <el-input v-model="volumn.volumn1" style="width:300px;" size="mini">
                                <template slot="prepend">{{"立方货形 = "}}</template>
                            </el-input>
                        </el-col>
                        <el-col :xs="24" style="padding:15px;" > 
                            <el-input v-model="volumn.volumn2" style="width:300px;" size="mini">
                                <template slot="prepend">{{"扁平货形 = "}}</template>
                            </el-input>
                        </el-col>
                        <el-col :xs="24" style="padding:15px;" > 
                            <el-input v-model="volumn.volumn3" style="width:300px;" size="mini">
                                <template slot="prepend">{{"条柱货形 ="}}</template>
                            </el-input>
                        </el-col>
                    </el-row>
                    <!-- <div style="text-align:center; margin-top: 15px; margin-bottom:15px;">
                        <el-button type="primary" size="mini"  @click="update"><i class="el-icon-check"></i><span>{{msg('确定')}}</span></el-button>
                    </div> -->
                </div>
            </el-tab-pane>
            <el-tab-pane :label="msg('虾皮宅配和黑猫发货匹配地址')" v-if="hasPermission('userSetting:printWarn')">
                <div class="row-wrap" >
                    <el-row v-for="(volumn, idx) in form.shipAddress" :key="idx">
                        <el-col :xs="24" :sm="12" :md="18" style="padding:15px;">
                            <el-form-item :label="msg('地址：')" >
                                <el-input v-model="volumn.xvalue" :placeholder="msg('地址必须和虾皮店铺预设的地址保持一致')"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </div>
            </el-tab-pane>
            <el-tab-pane :label="msg('自动识别设置')" v-if="hasPermission('userSetting:printWarn')">
                <div class="row-wrap" >
                    <span v-for="(warn, idx) in form.printWarns" :key="idx">
                        <el-row>
                            <el-col :xs="24" :sm="24" :md="16" style="padding: 10px 5px">
                                <el-form-item label-width="180px" :label="msg('打印时货物未到齐提醒：')" >
                                    <el-radio v-model="warn.printWarn" label="0">提醒</el-radio>
                                    <el-radio v-model="warn.printWarn" label="1">不提醒</el-radio>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :xs="24" :sm="24" :md="16" style="padding: 10px 5px">
                                <el-form-item label-width="180px" :label="msg('货单二维码设置：')" >
                                    <!-- <el-radio v-model="warn.printType" label="0">条形码</el-radio>
                                    <el-radio v-model="warn.printType" label="1">二维码</el-radio> -->
                                    <el-checkbox v-model="warn.printType">二维码</el-checkbox>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :xs="24" :sm="24" :md="16" style="padding: 10px 5px">
                                <span style="font-size:14px; color:#666666; margin-right:10px;">{{msg('架位识别规则')}}</span>
                                <el-tag v-for="(tag, idx) in pkgRules" :key="idx"
                                    :type="tag.type == 0 ? 'danger':''"
                                    closable @close="buildPkgRule(idx)"
                                    style="margin: 5px;"
                                >
                                    {{tag.name}}({{tag.len}})
                                </el-tag>
                            </el-col>
                            <el-col :xs="24" style="padding: 15px">
                                <el-select v-model="selectedRuleType" placeholder="请选择" size="mini">
                                    <el-option label="数字" value="数字"></el-option>
                                    <el-option label="字母" value="字母"></el-option>
                                    <el-option label="横杠" value="横杠"></el-option>
                                </el-select>
                                <el-input-number v-model="selectedRuleLength" :min="1" :max="10" size="mini" label="长度">
                                </el-input-number>
                                <el-button type="primary" @click="addPkgRule" plain size="mini" >添加</el-button>
                            </el-col>
                        </el-row>
                    </span>
                </div>
            </el-tab-pane>
            <el-tab-pane :label="msg('航空报表设置')" v-if="hasPermission('stock:downloadHangkong')">
                <div class="row-wrap" style="padding:30px;">
                    <el-table :data="hangkongs" ref="hangkongRef" border style="width: 100%" size="mini" highlight-current-row stripe>
                        <el-table-column label="航空资料">
                            <el-table-column type="selection" width="80"></el-table-column>
                            <el-table-column type="index" min-width="100"></el-table-column>
                            <el-table-column prop="key"  min-width="120" :label="msg('字段KEY')" show-overflow-tooltip></el-table-column>
                            <el-table-column prop="name"  min-width="120" :label="msg('字段NAME')" show-overflow-tooltip></el-table-column>
                            <el-table-column prop="name1"  min-width="120" :label="msg('字段显示名')" show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <el-input v-model="scope.row.name1" size="mini"></el-input>
                                </template>
                            </el-table-column>
                            <el-table-column prop="value"  min-width="120" :label="msg('固定值')" show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <el-input v-model="scope.row.value" size="mini"></el-input>
                                </template>
                            </el-table-column>
                            <el-table-column label="排序" min-width="100">
                                <template slot-scope="scope">
                                    <el-link @click="handleMoveUp(hangkongs, scope.$index)" icon="el-icon-top"></el-link>
                                    <el-link @click="handleMoveDown(hangkongs, scope.$index)" style="margin-left:5px;" icon="el-icon-bottom"></el-link>
                                </template>
                            </el-table-column>
                        </el-table-column>
                    </el-table>
                </div>
            </el-tab-pane>
            <el-tab-pane :label="msg('清关报表设置')" v-if="hasPermission('stock:downloadQingguan')">
                <div class="row-wrap" style="padding:30px;">
                    <el-table :data="qingguans" ref="qingguanRef" border style="width: 100%" size="mini" highlight-current-row stripe>
                        <el-table-column label="清关资料">
                            <el-table-column type="selection" width="80"></el-table-column>
                            <el-table-column type="index" min-width="100"></el-table-column>
                            <el-table-column prop="key"  min-width="120" :label="msg('字段KEY')" show-overflow-tooltip></el-table-column>
                            <el-table-column prop="name"  min-width="120" :label="msg('字段NAME')" show-overflow-tooltip></el-table-column>
                            <el-table-column prop="name1"  min-width="120" :label="msg('字段显示名')" show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <el-input v-model="scope.row.name1" size="mini"></el-input>
                                </template>
                            </el-table-column>
                            <el-table-column prop="value"  min-width="120" :label="msg('固定值')" show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <el-input v-model="scope.row.value" size="mini"></el-input>
                                </template>
                            </el-table-column>
                            <el-table-column label="排序" min-width="100">
                                <template slot-scope="scope">
                                    <el-link @click="handleMoveUp(qingguans, scope.$index)" icon="el-icon-top"></el-link>
                                    <el-link @click="handleMoveDown(qingguans, scope.$index)" style="margin-left:5px;" icon="el-icon-bottom"></el-link>
                                </template>
                            </el-table-column>
                        </el-table-column>
                    </el-table>
                </div>
            </el-tab-pane>
            <el-tab-pane :label="msg('派件报表设置')" v-if="hasPermission('stock:downloadHeimao')">
                <div class="row-wrap" style="padding:30px;">
                    <el-table :data="paijians" ref="paijianRef" border style="width: 100%" size="mini" highlight-current-row stripe>
                        <el-table-column label="派件资料">
                            <el-table-column type="selection" width="80"></el-table-column>
                            <el-table-column type="index" min-width="100"></el-table-column>
                            <el-table-column prop="key"  min-width="120" :label="msg('字段KEY')" show-overflow-tooltip></el-table-column>
                            <el-table-column prop="name"  min-width="120" :label="msg('字段NAME')" show-overflow-tooltip></el-table-column>
                            <el-table-column prop="name1"  min-width="120" :label="msg('字段显示名')" show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <el-input v-model="scope.row.name1" size="mini"></el-input>
                                </template>
                            </el-table-column>
                            <el-table-column prop="value"  min-width="120" :label="msg('固定值')" show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <el-input v-model="scope.row.value" size="mini"></el-input>
                                </template>
                            </el-table-column>
                            <el-table-column label="排序" min-width="100">
                                <template slot-scope="scope">
                                    <el-link @click="handleMoveUp(paijians, scope.$index)" icon="el-icon-top"></el-link>
                                    <el-link @click="handleMoveDown(paijians, scope.$index)" style="margin-left:5px;" icon="el-icon-bottom"></el-link>
                                </template>
                            </el-table-column>
                        </el-table-column>
                    </el-table>
                </div>
            </el-tab-pane>
            <el-tab-pane :label="msg('新竹派件设置')" v-if="hasPermission('stock:downloadHeimao')">
                <div class="row-wrap" style="padding:30px;">
                    <el-table :data="xinzhus" ref="xinzhuRef" border style="width: 100%" size="mini" highlight-current-row stripe>
                        <el-table-column label="新竹派件资料">
                            <el-table-column type="selection" width="80"></el-table-column>
                            <el-table-column type="index" min-width="100"></el-table-column>
                            <el-table-column prop="key"  min-width="120" :label="msg('字段KEY')" show-overflow-tooltip></el-table-column>
                            <el-table-column prop="name"  min-width="120" :label="msg('字段NAME')" show-overflow-tooltip></el-table-column>
                            <el-table-column prop="name1"  min-width="120" :label="msg('字段显示名')" show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <el-input v-model="scope.row.name1" size="mini"></el-input>
                                </template>
                            </el-table-column>
                            <el-table-column prop="value"  min-width="120" :label="msg('固定值')" show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <el-input v-model="scope.row.value" size="mini"></el-input>
                                </template>
                            </el-table-column>
                            <el-table-column label="排序" min-width="100">
                                <template slot-scope="scope">
                                    <el-link @click="handleMoveUp(xinzhus, scope.$index)" icon="el-icon-top"></el-link>
                                    <el-link @click="handleMoveDown(xinzhus, scope.$index)" style="margin-left:5px;" icon="el-icon-bottom"></el-link>
                                </template>
                            </el-table-column>
                        </el-table-column>
                    </el-table>
                </div>
            </el-tab-pane>
            <!-- v-if="hasPermission('userSetting:strongPwd')" -->
            <el-tab-pane :label="msg('个人安全密码')">
              <div class="row-wrap" >
                <el-row v-for="(pwd, idx) in form.strongPwd" :key="idx">
                  <el-col :xs="24" :sm="24" style="padding:15px;">
                    <el-link style="margin-top:8px;" type="danger" disabled>{{msg('密码规则：密码长度大于4位，仅可包含字母、数字及以下特殊字符：~!@#$%^&*-_.')}}</el-link>
                  </el-col>
                  <el-col :xs="24" style="padding:15px;">
                    <el-input v-model="pwd.xvalue" style="width:300px;" size="mini" type="password">
                      <template slot="prepend">{{"请输入新的个人安全密码"}}</template>
                    </el-input>
                  </el-col>
                </el-row>
              </div>
          </el-tab-pane>
            <div style="text-align:center; margin-top: 15px; margin-bottom:15px;">
                <el-button type="primary" size="mini"  @click="update"><i class="el-icon-check"></i><span>{{msg('确定')}}</span></el-button>
            </div>
        </el-tabs>
    </el-form>
    <el-dialog :title="msg('验证旧安全密码')" :visible.sync="strongPwdVisiable" :close-on-click-modal="false">
      <StrongPwd v-on:close-dg="kit.strongAuthData.closeEvent"/>
    </el-dialog>
  </div>
</template>

<script>
import PreviewTpl from "./PrintPageTplPreview.vue";
import StrongPwd from "@/views/sys/StrongPwd.vue";
export default {
    name: "UserSetting",
    components:{
        StrongPwd,
        PreviewTpl : PreviewTpl,
    },
    data() {
        return {
            comTest: this.$kit.api.user.comTest,
            ecGroup: 'ECSETTING',  //电子秤group
            tpGroup: 'TPSETTING', //货单打印group
            awGroup: "ATTACH_WEIGHT", //附加重量
            vfGroup: "VOLUMN_FORMULA", //材积计算
            pintGroup: "PRINT_WARN", //打印提醒
            addressGroup: "XIAPI_ADDRESS", //虾皮宅配和黑猫地址设置
            hangkongGroup: "HANGKONG_REPORT",//航空报表设置
            qingguanGroup: "QINGGUAN_REPORT",//清关报表设置
            paijianGroup: "PAIJIAN_REPORT",//派件报表设置
            xinzhuGroup: "XINZHU_REPORT",//派件报表设置
            strongPwd:"strongPwd",
            strongPwdVisiable: false, //个人安全密码验证窗

            pkgRules: [],
            selectedRuleType: null,
            selectedRuleLength: null,
            pkgRuleRegExp: null,
            pkgRuleLength: null,

            form: {
                ecsetting: [], //电子秤微调设置
                ticketSettings: [], //货单打印设置
                attachWeights:[],//附加重量
                volumns:[],//材积计算
                printWarns:[],
                hks:[],
                qgs:[],
                pjs:[],
                xzs:[],
                shipAddress:[],
                strongPwd:[],
            },
            hangkongs:[
                {
                    key:'exp_pkg_code',
                    name:'清關條碼',
                    name1:'清關條碼',
                    value:'',  
                    show:null,
                },
                {
                    key:'code',
                    name:'提單號碼',
                    name1:'提單號碼',
                    value:'',  
                    show:null,
                },
                {
                    key:'expPageCode',
                    name:'面單碼',
                    name1:'面單碼',
                    value:'',
                    show:null,
                },
                {
                    key:'expCode',
                    name:'查詢碼',
                    name1:'查詢碼',
                    value:'',
                    show:null,
                },
                {
                    key:'product_name',
                    name:'品名',
                    name1:'品名',
                    value:'',  
                    show:null,
                },
                {
                    key:'pkg_qty',
                    name:'袋數',
                    name1:'袋數',
                    value:'',  
                    show:null,
                },
                {
                    key:'weight_pkg',
                    name:'袋重',
                    name1:'袋重',
                    value:'',  
                    show:null,
                },
                {
                    key:'count',
                    name:'數量',
                    name1:'數量',
                    value:'',  
                    show:null,
                },
                {
                    key:'weight_exp',
                    name:'重量',
                    name1:'重量',
                    value:'',  
                    show:null,
                },
                {
                    key:'remark',
                    name:'備註',
                    name1:'備註',
                    value:'',  
                    show:null,
                },
                {
                    key:'order_origin',
                    name:'订单来源',
                    name1:'订单来源',
                    value:'',  
                    show:null,
                },
                {
                    key:'exp_out_type',
                    name:'出货方式',
                    name1:'出货方式',
                    value:'',  
                    show:null,
                },
                {
                    key:'exp_batch',
                    name:'批次',
                    name1:'批次',
                    value:'',  
                    show:null,
                },
                {
                    key:'remark1',
                    name:'',
                    name1:'',
                    value:'',  
                    show:null,
                },
                {
                    key:'remark2',
                    name:'',
                    name1:'',
                    value:'',  
                    show:null,
                },
            ],
            qingguans:[
                {
                    key:'index',
                    name:'編號',
                    name1:'編號',
                    value:'',
                    show:null,
                },
                {
                    key:'exp_pkg_code',
                    name:'袋號',
                    name1:'袋號',
                    value:'',
                    show:null,
                },
                {
                    key:'code',
                    name:'提單號碼',
                    name1:'提單號碼',
                    value:'',
                    show:null,
                },
                {
                    key:'expPageCode',
                    name:'面单码',
                    name1:'面单码',
                    value:'',
                    show:null,
                },
                {
                    key:'expCode',
                    name:'查询码',
                    name1:'查询码',
                    value:'',
                    show:null,
                },
                {
                    key:'jianshu',
                    name:'件數',
                    name1:'件數',
                    value:'',
                    show:null,
                },
                {
                    key:'weight_exp',
                    name:'提單重量(KG)',
                    name1:'提單重量(KG)',
                    value:'',
                    show:null,
                },
                {
                    key:'product_name',
                    name:'品名',
                    name1:'品名',
                    value:'',
                    show:null,
                },
                {
                    key:'order_qty',
                    name:'數量',
                    name1:'數量',
                    value:'',
                    show:null,
                },
                {
                    key:'pce',
                    name:'單位',
                    name1:'單位',
                    value:'',
                    show:null,
                },
                {
                    key:'cn',
                    name:'產地',
                    name1:'產地',
                    value:'',
                    show:null,
                },
                {
                    key:'twd',
                    name:'單價(TWD)',
                    name1:'單價(TWD)',
                    value:'',
                    show:null,
                },
                {
                    key:'jijian',
                    name:'寄件公司',
                    name1:'寄件公司',
                    value:'',
                    show:null,
                },
                {
                    key:'jijianren',
                    name:'寄件人',
                    name1:'寄件人',
                    value:'',
                    show:null,
                },
                {
                    key:'shoujiantb',
                    name:'收件統編',
                    name1:'收件統編',
                    value:'',
                    show:null,
                },
                {
                    key:'receiver',
                    name:'收件人',
                    name1:'收件人',
                    value:'',
                    show:null,
                },
                {
                    key:'receiver_tel',
                    name:'收件公司電話',
                    name1:'收件公司電話',
                    value:'',
                    show:null,
                },
                {
                    key:'receiver_addr',
                    name:'收件地址',
                    name1:'收件地址',
                    value:'',
                    show:null,
                },
                {
                    key:'shenfenz',
                    name:'統編/身份證號碼',
                    name1:'統編/身份證號碼',
                    value:'',
                    show:null,
                },
                {
                    key:'leibie',
                    name:'報單類別',
                    name1:'報單類別',
                    value:'',
                    show:null,
                },
                {
                    key:'shuize',
                    name:'稅則',
                    name1:'稅則',
                    value:'',
                    show:null,
                },
                {
                    key:'nashui',
                    name:'納稅辦法',
                    name1:'納稅辦法',
                    value:'',
                    show:null,
                },
                {
                    key:'exp_company',
                    name:'派件公司',
                    name1:'派件公司',
                    value:'',
                    show:null,
                },
                {
                    key:'weight_pkg',
                    name:'袋重',
                    name1:'袋重',
                    value:'',
                    show:null,
                },
                {
                    key:'order_origin',
                    name:'订单来源',
                    name1:'订单来源',
                    value:'',
                    show:null,
                },
                {
                    key:'exp_out_type',
                    name:'出货方式',
                    name1:'出货方式',
                    value:'',
                    show:null,
                },
                {
                    key:'exp_batch',
                    name:'批次',
                    name1:'批次',
                    value:'',
                    show:null,
                },
                {
                    key:'ownerName',
                    name:'归属人',
                    name1:'归属人',
                    value:'',
                    show:null,
                },
                {
                    key:'order_bag_code',
                    name:'包号',
                    name1:'包号',
                    value:'',
                    show:null,
                },
                {
                    key:'weight_cust_pkg',
                    name:'包重',
                    name1:'包重',
                    value:'',
                    show:null,
                },
                {
                    key:'product_volume_str',
                    name:'体积',
                    name1:'体积',
                    value:'',
                    show:null,
                },
                {
                    key:'remark1',
                    name:'',
                    name1:'',
                    value:'',  
                    show:null,
                },
                {
                    key:'remark2',
                    name:'',
                    name1:'',
                    value:'',  
                    show:null,
                },
            ],
            paijians:[
                {
                    key:'currdate',
                    name:'出貨日期',
                    name1:'出貨日期',
                    value:'',
                    show:null,
                },
                {
                    key:'exp_code',
                    name:'黑貓換單',
                    name1:'黑貓換單',
                    value:'',
                    show:null,
                },
                {
                    key:'null',
                    name:'',
                    name1:'',
                    value:'',
                    show:null,
                },
                {
                    key:'code',
                    name:'訂單編號',
                    name1:'訂單編號',
                    value:'',
                    show:null,
                },
                {
                    key:'exp_pkg_code',
                    name:'袋號',
                    name1:'袋號',
                    value:'',
                    show:null,
                },
                {
                    key:'kedai',
                    name:'客代',
                    name1:'客代',
                    value:'',
                    show:null,
                },
                {
                    key:'receiver',
                    name:'收件人姓名',
                    name1:'收件人姓名',
                    value:'',
                    show:null,
                },
                {
                    key:'receiver_tel',
                    name:'收件人電話1',
                    name1:'收件人電話1',
                    value:'',
                    show:null,
                },
                {
                    key:'receiver_tel2',
                    name:'收件人電話2',
                    name1:'收件人電話2',
                    value:'',
                    show:null,
                },
                {
                    key:'receiver_addr',
                    name:'地址',
                    name1:'地址',
                    value:'',
                    show:null,
                },
                {
                    key:'product_name',
                    name:'內容物',
                    name1:'內容物',
                    value:'',
                    show:null,
                },
                {
                    key:'count',
                    name:'數量',
                    name1:'數量',
                    value:'',
                    show:null,
                },
                {
                    key:'weight_exp',
                    name:'重量',
                    name1:'重量',
                    value:'',
                    show:null,
                },
                {
                    key:'amt_order',
                    name:'代收金額',
                    name1:'代收金額',
                    value:'',
                    show:null,
                },
                {
                    key:'kefu',
                    name:'備註',
                    name1:'備註',
                    value:'',
                    show:null,
                },
                {
                    key:'chuhuo',
                    name:'出貨廠商名稱',
                    name1:'出貨廠商名稱',
                    value:'',
                    show:null,
                },
                {
                    key:'bianma',
                    name:'7碼郵號',
                    name1:'7碼郵號',
                    value:'',
                    show:null,
                },
                {
                    key:'nextdate',
                    name:'配送日',
                    name1:'配送日',
                    value:'',
                    show:null,
                },
                {
                    key:'shiduan',
                    name:'配送時段',
                    name1:'配送時段',
                    value:'',
                    show:null,
                },
                {
                    key:'dizhi',
                    name:'出貨廠商地址',
                    name1:'出貨廠商地址',
                    value:'',
                    show:null,
                },
                {
                    key:'dianhua',
                    name:'出貨廠商電話',
                    name1:'出貨廠商電話',
                    value:'',
                    show:null,
                },
                {
                    key:'remark1',
                    name:'',
                    name1:'',
                    value:'',  
                    show:null,
                },
                {
                    key:'remark2',
                    name:'',
                    name1:'',
                    value:'',  
                    show:null,
                },
            ],
            xinzhus:[
                {
                    key:'currdate',
                    name:'出貨日期',
                    name1:'出貨日期',
                    value:'',
                    show:null,
                },
                {
                    key:'exp_code',
                    name:'新竹託運單號',
                    name1:'新竹託運單號',
                    value:'',
                    show:null,
                },
                {
                    key:'null',
                    name:'換單單號',
                    name1:'換單單號',
                    value:'',
                    show:null,
                },
                {
                    key:'code',
                    name:'訂單編號',
                    name1:'訂單編號',
                    value:'',
                    show:null,
                },
                {
                    key:'exp_pkg_code',
                    name:'袋號',
                    name1:'袋號',
                    value:'',
                    show:null,
                },
                {
                    key:'kedai',
                    name:'客戶代號',
                    name1:'客戶代號',
                    value:'',
                    show:null,
                },
                {
                    key:'receiver',
                    name:'收件人',
                    name1:'收件人',
                    value:'',
                    show:null,
                },
                {
                    key:'receiver_tel',
                    name:'收件人電話1',
                    name1:'收件人電話1',
                    value:'',
                    show:null,
                },
                {
                    key:'receiver_tel2',
                    name:'收件人電話2',
                    name1:'收件人電話2',
                    value:'',
                    show:null,
                },
                {
                    key:'receiver_addr',
                    name:'收件人地址',
                    name1:'收件人地址',
                    value:'',
                    show:null,
                },
                {
                    key:'product_name',
                    name:'品名',
                    name1:'品名',
                    value:'',
                    show:null,
                },
                {
                    key:'count',
                    name:'數量',
                    name1:'數量',
                    value:'',
                    show:null,
                },
                {
                    key:'weight_exp',
                    name:'重量',
                    name1:'重量',
                    value:'',
                    show:null,
                },
                {
                    key:'amt_order',
                    name:'台幣代收款',
                    name1:'台幣代收款',
                    value:'',
                    show:null,
                },
                {
                    key:'kefu',
                    name:'備註',
                    name1:'備註',
                    value:'',
                    show:null,
                },
                {
                    key:'chuhuo',
                    name:'寄件人名稱',
                    name1:'寄件人名稱',
                    value:'',
                    show:null,
                },
                {
                    key:'bianma',
                    name:'時間代',
                    name1:'時間代',
                    value:'',
                    show:null,
                },
                {
                    key:'nextdate',
                    name:'寄件人地址',
                    name1:'寄件人地址',
                    value:'',
                    show:null,
                },
                {
                    key:'shiduan',
                    name:'寄件人客服電話',
                    name1:'寄件人客服電話',
                    value:'',
                    show:null,
                },
                {
                    key:'remark1',
                    name:'',
                    name1:'',
                    value:'',  
                    show:null,
                },
                {
                    key:'remark2',
                    name:'',
                    name1:'',
                    value:'',  
                    show:null,
                },
                {
                    key:'exp_batch',
                    name:'批次',
                    name1:'',
                    value:'',  
                    show:null,
                },
            ],
        }
    }, 
    mounted(){
        this.getSettings();
    },
    computed: {
        docHeight: {  //表格的高度
            get() {
                var height =  this.$store.state.docHeight;
                return height - 120;
            }
        },
    },
    methods: {
        getSettings(){
            var _this = this;
            this.$axios.get(this.$kit.api.user.settingList)
                .then((result) => {
                    if(result.status){
                        this.buildViewData(result.data); //构建设置对象
                    }
                });
        },
        //添加袋号自动录入规则
        addPkgRule(){
            if(this.selectedRuleType && this.selectedRuleLength){
                
                this.pkgRules.push({
                    type: this.selectedRuleType == '数字'?0:this.selectedRuleType == '字母'?1:2,
                    name: this.selectedRuleType,
                    len: this.selectedRuleLength,
                });

                this.buildPkgRule(-1);
                this.selectedRuleType = null;
                this.selectedRuleLength = null;

            }
        },
        buildPkgRule(index){
            if(index>-1){
                this.pkgRules.splice(index, 1);
            }
            if(this.pkgRules.length==0){
                this.pkgRuleRegExp = null;
                return;
            }

            let regStr = "";
            let len = 0;
            this.pkgRules.forEach(r => {
                regStr += (r.type == 0?"[0-9]":"[a-zA-Z]");
                regStr += "{"+r.len+"}";
                len += r.len;
            });
            
            this.pkgRuleRegExp = new RegExp(regStr);
            this.pkgRuleLength = len;

        },
        buildViewData(data){
            var _this = this;
            _this.form.ecsetting = [];
            _this.form.ticketSettings = [];
            _this.form.attachWeights = [];
            _this.form.volumns = [];
            _this.form.printWarns = [];
            _this.form.hks = [];
            _this.form.qgs = [];
            _this.form.pjs = [];
            _this.form.xzs = [];
            _this.form.shipAddress = [];
            _this.form.strongPwd = [];
            
            if(data && data.length > 0){
                let arr = []; //货单打印页面数据
                let tp = [];  //电子秤数据
                let attachs = []; //附加重量数据
                let volumns = []; //材积计算
                let printWarns = [];//打印提醒
                let hangkongReports = [];//航空报表设置
                let qingguanReports = [];//清关报表设置
                let paijianReports = [];//派件报表设置
                let xinzhuReports = [];//新竹报表设置
                let address = [];
                let strongPwd = [];
                data.forEach(d => {
                    if(d.xgroup == this.ecGroup){
                        arr.push(d);
                    }else if(d.xgroup == this.tpGroup){
                        tp.push(d);
                    }else if(d.xgroup == this.awGroup){
                        attachs.push(d);
                    }else if(d.xgroup == this.vfGroup){
                        volumns.push(d);
                    }else if(d.xgroup == this.pintGroup){
                        printWarns.push(d);
                    }else if(d.xgroup == this.hangkongGroup){
                        hangkongReports.push(d);
                    }else if(d.xgroup == this.qingguanGroup){
                        qingguanReports.push(d);
                    }else if(d.xgroup == this.paijianGroup){
                        paijianReports.push(d);
                    }else if(d.xgroup == this.xinzhuGroup){
                        xinzhuReports.push(d);
                    }else if(d.xgroup == this.addressGroup){
                        address.push(d);
                    }else if(d.xgroup == this.strongPwd){
                        strongPwd.push(d);
                    }
                });

                //相同的xkey，表示同一个设置
                //电子秤数据
                if(arr.length>0){
                    arr.forEach(a => {
                        if(a.xvalue){
                            let set = JSON.parse(a.xvalue);
                            let ecset = {
                                id: a.id,
                                disabled: a.disabled,
                                name: set.name,
                                port: set.port,
                                express: set.express,
                                owner: a.owner
                            }
                            _this.form.ecsetting.push(ecset);
                        }
                    });
                }
                if(address.length>0){
                    address.forEach(a=>{
                        if(a.xvalue){
                            let addset = {
                                id: a.id,
                                xvalue:a.xvalue,
                                owner: a.owner
                            }
                            _this.form.shipAddress.push(addset);
                        }
                    })
                }else{
                    let def = {
                        id:null,
                        owner:null,
                        xvalue:""
                    }
                    _this.form.shipAddress.push(def);
                }

                //货单打印页面数据
                if(tp.length>0){
                    tp.forEach(a => {
                        if(a.xvalue){
                            let set = JSON.parse(a.xvalue);
                            let ticket = {
                                id: a.id,
                                disabled: a.disabled,
                                owner: a.owner,
                                name: set.name,
                                invoice: set.invoice,
                                invoiceHead: set.invoiceHead,
                                invoiceCompany: set.invoiceCompany,
                                invoiceBarCode: set.invoiceBarCode,
                                invoiceInfo: set.invoiceInfo,
                                invoiceCode: set.invoiceCode,
                                invoiceSender: set.invoiceSender,
                                invoiceReceiver: set.invoiceReceiver,
                                invoiceTel: set.invoiceTel,
                                invoiceAddr: set.invoiceAddr,
                                pkgType: set.pkgType,

                                invoiceTable: set.invoiceTable,
                                invoiceTableName: set.invoiceTableName,
                                invoiceTableQty: set.invoiceTableQty,
                                invoiceTablePrice: set.invoiceTablePrice,
                                invoiceTableAmount: set.invoiceTableAmount,

                                detail: set.detail,
                                detailCode: set.detailCode,
                                detailShelfCode: set.detailShelfCode,
                                erweima: set.erweima,
                                tiaoma: set.tiaoma,
                                detailName: set.detailName,
                                detailTitle: set.detailTitle,
                                detailSpec: set.detailSpec,
                                detailStoreType: set.detailStoreType,
                                detailPkg: set.detailPkg,
                                detailDate: set.detailDate,
                                detailRemark1: set.detailRemark1,
                                detailRemark2: set.detailRemark2,
                                note: set.note,
                                shopSellerMsg: set.shopSellerMsg,
                                shopName: set.shopName,
                                ownerName:set.ownerName,
                                receiver:set.receiver,
                                expGoodType:set.expGoodType,
                                orderPlatform:set.orderPlatform,
                                detailSku:set.detailSku,
                            }
                            _this.form.ticketSettings.push(ticket);
                        }
                    });
                }

                //附加重量
                if(attachs.length>0){
                    attachs.forEach(a => {
                        if(a.xvalue){
                            let ws = JSON.parse(a.xvalue);

                            let weight = ws.addedWeight;
                            let pweight = ws.addedPercentWeight;
                            let type = ws.addedWeightType;

                            if(type == 1){
                                pweight = pweight * 100;
                            }

                            let wsset = {
                                id: a.id,
                                disabled: a.disabled,
                                addedWeightType: type,
                                addedWeight: weight,
                                addedPercentWeight: pweight,
                                owner: a.owner,
                                xlabel: a.xlabel
                            }
                            _this.form.attachWeights.push(wsset);
                        }
                    });
                }

                //材积计算
                if(volumns.length > 0){
                    volumns.forEach(a => {
                        if(a.xvalue){
                            let ws = JSON.parse(a.xvalue);
                            let volumn1 = ws.volumn1;
                            let volumn2 = ws.volumn2;
                            let volumn3 = ws.volumn3;
                            let warn = {
                                id: a.id,
                                volumn1:volumn1,
                                volumn2:volumn2,
                                volumn3:volumn3,
                                owner: a.owner,
                            }
                            _this.form.volumns.push(warn);
                        }
                    })
                }else{
                     _this.form.volumns.push({
                        id: null,
                        xkey: 'VOLUMN_FORMULA',
                        owner: null,
                        xgroup: 'VOLUMN_FORMULA',
                        volumn1: '长*宽*高/27000',
                        volumn2: '长*宽/900',
                        volumn3: '长/30',
                        xtype:1
                        // xvalue: JSON.stringify({
                        //     volumn1: '长*宽*高/27000',
                        //     volumn2: '长*宽/900',
                        //     volumn3: '长/30'
                        // }),
                    })
                }

                //打印提醒
                if(printWarns.length > 0){
                    printWarns.forEach(a => {
                        if(a.xvalue){
                            let ws = JSON.parse(a.xvalue);
                            let printWarn = ws.printWarn?ws.printWarn:0;
                            let pkgRules = ws.pkgRules?ws.pkgRules:[];
                            let printType = ws.printType?true:false;
                            let warn = {
                                id: a.id?a.id:null,
                                xkey: 'PRINT_WARN',
                                owner: a.owner?a.owner:null,
                                xgroup: 'PRINT_WARN',
                                // xvalue: a.xvalue,
                                printWarn:printWarn,
                                printType:printType,
                                pkgRules:pkgRules,
                                xtype:1
                            }
                            _this.form.printWarns.push(warn);
                            this.pkgRules = pkgRules;
                        }
                    })
                }else{
                    _this.form.printWarns.push({
                        id: null,
                        xkey: 'PRINT_WARN',
                        owner: null,
                        xgroup: 'PRINT_WARN',
                        // xvalue: 0,
                        printWarn:0,
                        printType:false,
                        pkgRules:[],
                        xtype:0
                    })
                }
                //航空报表
                if(hangkongReports.length > 0){
                    hangkongReports.forEach(a => {
                        if(a.xvalue){
                            let hk = {
                                id: a.id?a.id:null,
                                xkey: _this.hangkongGroup,
                                owner: a.owner?a.owner:null,
                                xgroup: _this.hangkongGroup,
                                xvalue: a.xvalue,
                                xtype:1
                            }
                            _this.form.hks.push(hk);
                        }
                    })
                    let hks = hangkongReports[0];
                    let hk = JSON.parse(hks.xvalue);
                    if(hk && hk.length > 0){
                        _this.hangkongs.splice(0,_this.hangkongs.length);
                        hk.forEach(e => {
                            _this.hangkongs.push(
                                {
                                    key:e.key,
                                    name:e.name,
                                    name1:e.name1,
                                    value:e.value,  
                                    show:e.show,
                                },
                            )
                        })
                        _this.hangkongs.forEach(e => {
                            if(e.show){
                                _this.$refs.hangkongRef.toggleRowSelection(e,true);
                            }
                        })
                    }
                }else{
                    _this.form.hks.push({
                        id: null,
                        xkey: _this.hangkongGroup,
                        owner: null,
                        xgroup: _this.hangkongGroup,
                        xvalue: 0,
                        xtype:0
                    })
                    if(this.hasPermission('stock:downloadHangkong')){
                        _this.hangkongs.forEach(e => {
                            _this.$refs.hangkongRef.toggleRowSelection(e);
                        })
                    }
                }

                //清关报表
                if(qingguanReports.length > 0){
                    qingguanReports.forEach(a => {
                        if(a.xvalue){
                            let qg = {
                                id: a.id?a.id:null,
                                xkey: _this.qingguanGroup,
                                owner: a.owner?a.owner:null,
                                xgroup: _this.qingguanGroup,
                                xvalue: a.xvalue,
                                xtype:1
                            }
                            _this.form.qgs.push(qg);
                        }
                    })
                    let qgs = qingguanReports[0];
                    let qg = JSON.parse(qgs.xvalue);
                    if(qg && qg.length > 0){
                        _this.qingguans.splice(0,_this.qingguans.length);
                        qg.forEach(e => {
                            _this.qingguans.push(
                                {
                                    key:e.key,
                                    name:e.name,
                                    name1:e.name1,
                                    value:e.value,  
                                    show:e.show,
                                },
                            )
                        })
                        _this.qingguans.forEach(e => {
                            if(e.show){
                                _this.$refs.qingguanRef.toggleRowSelection(e);
                            }
                        })
                    }
                }else{
                    _this.form.qgs.push({
                        id: null,
                        xkey: _this.qingguanGroup,
                        owner: null,
                        xgroup: _this.qingguanGroup,
                        xvalue: 0,
                        xtype:0
                    })
                    if(this.hasPermission('stock:downloadQingguan')){
                        _this.qingguans.forEach(e => {
                            _this.$refs.qingguanRef.toggleRowSelection(e);
                        })
                    }
                }

                //派件报表
                if(paijianReports.length > 0){
                    paijianReports.forEach(a => {
                        if(a.xvalue){
                            let pj = {
                                id: a.id?a.id:null,
                                xkey: _this.paijianGroup,
                                owner: a.owner?a.owner:null,
                                xgroup: _this.paijianGroup,
                                xvalue: a.xvalue,
                                xtype:1
                            }
                            _this.form.pjs.push(pj);
                        }
                    })
                    let pjs = paijianReports[0];
                    let pj = JSON.parse(pjs.xvalue);
                    if(pj && pj.length > 0){
                        _this.paijians.splice(0,_this.paijians.length);
                        pj.forEach(e => {
                            _this.paijians.push(
                                {
                                    key:e.key,
                                    name:e.name,
                                    name1:e.name1,
                                    value:e.value,  
                                    show:e.show,
                                },
                            )
                        })
                        _this.paijians.forEach(e => {
                            if(e.show){
                                _this.$refs.paijianRef.toggleRowSelection(e);
                            }
                        })
                    }
                }else{
                    this.form.pjs.push({
                        id: null,
                        xkey: _this.paijianGroup,
                        owner: null,
                        xgroup: _this.paijianGroup,
                        xvalue: 0,
                        xtype:0
                    })
                    if(this.hasPermission('stock:downloadHeimao')){
                        _this.paijians.forEach(e => {
                            _this.$refs.paijianRef.toggleRowSelection(e);
                        })
                    }
                }

                //新竹派件报表
                if(xinzhuReports.length > 0){
                    xinzhuReports.forEach(a => {
                        if(a.xvalue){
                            let xz = {
                                id: a.id?a.id:null,
                                xkey: _this.xinzhuGroup,
                                owner: a.owner?a.owner:null,
                                xgroup: _this.xinzhuGroup,
                                xvalue: a.xvalue,
                                xtype:1
                            }
                            _this.form.xzs.push(xz);
                        }
                    })
                    let xzs = xinzhuReports[0];
                    let xz = JSON.parse(xzs.xvalue);
                    if(xz && xz.length > 0){
                        _this.xinzhus.splice(0,_this.xinzhus.length);
                        xz.forEach(e => {
                            _this.xinzhus.push(
                                {
                                    key:e.key,
                                    name:e.name,
                                    name1:e.name1,
                                    value:e.value,  
                                    show:e.show,
                                },
                            )
                        })
                        _this.xinzhus.forEach(e => {
                            if(e.show){
                                _this.$refs.xinzhuRef.toggleRowSelection(e);
                            }
                        })
                    }
                }else{
                    this.form.xzs.push({
                        id: null,
                        xkey: _this.xinzhuGroup,
                        owner: null,
                        xgroup: _this.xinzhuGroup,
                        xvalue: 0,
                        xtype:0
                    })
                    if(this.hasPermission('stock:downloadHeimao')){
                        _this.xinzhus.forEach(e => {
                            _this.$refs.xinzhuRef.toggleRowSelection(e);
                        })
                    }
                }

                //个人安全密码
                if (strongPwd.length > 0) {
                  var item = strongPwd[0];
                  item.xvalue = '';
                  item.hasValue = true;
                  _this.form.strongPwd.push(item);
                }else{
                  _this.form.strongPwd.push({
                    id: null,
                    xgroup: 'strongPwd',
                    xkey: 'strongPwd',
                    xtype: 1,
                    owner: null,
                    xvalue: ''
                  });
                }
            }else{
                this.form.printWarns.push({
                    id: null,
                    xkey: 'PRINT_WARN',
                    owner: null,
                    xgroup: 'PRINT_WARN',
                    printWarn:0,
                    printType:false,
                    pkgRules:[],
                    xtype:1
                })
                this.form.shipAddress.push({
                        id:null,
                        owner:null,
                        xvalue:""
                    });

                if(this.hasPermission('stock:downloadHangkong')){
                    _this.form.hks.push({
                        id: null,
                        xkey: _this.hangkongGroup,
                        owner: null,
                        xgroup: _this.hangkongGroup,
                        xvalue: 0,
                        xtype:0
                    })
                    _this.hangkongs.forEach(e => {
                        _this.$refs.hangkongRef.toggleRowSelection(e);
                    })
                }

                if(this.hasPermission('stock:downloadQingguan')){
                    _this.form.qgs.push({
                        id: null,
                        xkey: _this.qingguanGroup,
                        owner: null,
                        xgroup: _this.qingguanGroup,
                        xvalue: 0,
                        xtype:0
                    })
                    _this.qingguans.forEach(e => {
                        _this.$refs.qingguanRef.toggleRowSelection(e);
                    })
                }

                if(this.hasPermission('stock:downloadHeimao')){
                    _this.form.pjs.push({
                        id: null,
                        xkey: _this.paijianGroup,
                        owner: null,
                        xgroup: _this.paijianGroup,
                        xvalue: 0,
                        xtype:0
                    })
                    _this.paijians.forEach(e => {
                        _this.$refs.paijianRef.toggleRowSelection(e);
                    })
                }

                if(this.hasPermission('stock:downloadHeimao')){
                    _this.form.xzs.push({
                        id: null,
                        xkey: _this.xinzhuGroup,
                        owner: null,
                        xgroup: _this.xinzhuGroup,
                        xvalue: 0,
                        xtype:0
                    })
                    _this.xinzhus.forEach(e => {
                        _this.$refs.xinzhuRef.toggleRowSelection(e);
                    })
                }

                 _this.form.strongPwd.push({
                    id: null,
                    xgroup: 'strongPwd',
                    xkey: 'strongPwd',
                    xtype: 1,
                    owner: null,
                    xvalue: ''
                  });
            }
        },
        addEc(){
            this.form.ecsetting.push({
                id: null,
                disabled: 0,
                name: null,
                port: 9600,
                express: null,
                owner: null,
            });
        },
        addWeight(){
            this.form.attachWeights.push({
                id: null,
                disabled: 0,
                addedWeightType: 0,
                addedWeight: null,
                addedPercentWeight: null,
                owner: null,
                xlabel: "设置"+(this.form.attachWeights.length+1),
            });
        },
        addTs(){
            this.form.ticketSettings.push({
                id: null,
                disabled:0,
                owner: null,
                name: null,
                invoice: true,
                invoiceHead: true,
                invoiceCompany: true,
                invoiceBarCode: true,
                invoiceInfo: true,
                invoiceCode: true,
                invoiceSender: true,
                invoiceReceiver: true,
                invoiceTel: true,
                invoiceAddr: true,
                pkgType: true,

                invoiceTable: true,
                invoiceTableName: true,
                invoiceTableQty: true,
                invoiceTablePrice: true,
                invoiceTableAmount: true,
                erweima: true,
                tiaoma: true,
                detail: true,
                detailCode: true,
                detailShelfCode: true,
                detailName: true,
                detailTitle: true,
                detailSpec: true,
                detailStoreType:false,
                detailPkg: true,
                detailDate: true,
                detailRemark1: true,
                detailRemark2: true,
                note:true,
                shopSellerMsg: true,
                shopName:false,
                ownerName:false,
                receiver:false,
                expGoodType:false,
                orderPlatform:false,
                detailSku:false,
            })
        },
        handleMoveUp(datas, index){
            if(index == 0){
                return;
            }
            let row = datas[index];
            let prevRow = datas[index-1];
            //触发数组变动
            datas.splice(index, 1, prevRow);
            datas.splice(index-1, 1, row);
        },
        handleMoveDown(datas, index){
            if(index >= datas.length-1){
                return;
            }
            let row = datas[index];
            let lastRow = datas[index+1];
            datas.splice(index, 1, lastRow);
            datas.splice(index+1, 1, row);
        },
        update(){
            let datas = [];
            //构建电子秤设置
            for(let i=0; i<this.form.ecsetting.length; i++){
                let e = this.form.ecsetting[i];
                let key = this.ecGroup+i;
                datas.push({
                    id: e.id,
                    disabled: e.disabled,
                    xkey: key,
                    xlabel: e.name,
                    xvalue: JSON.stringify({
                        name: e.name,
                        port: e.port,
                        express: e.express
                    }),
                    owner: e.owner?e.owner:null,
                    xgroup: this.ecGroup
                });
            }
            //构建货单打印设置
            let dis = false;
            for (let i = 0; i < this.form.ticketSettings.length; i++) {
                let e = this.form.ticketSettings[i];
                if(!e.name){
                    this.$message(this.msg('请输入货单设置名称'));
                    return;
                }
                let key = this.tpGroup+i;
                datas.push({
                    id:e.id,
                    disabled: e.disabled,
                    xkey: key,
                    xlabel: e.name,
                    xvalue: JSON.stringify(e),
                    owner: e.owner?e.owner:null,
                    xgroup: this.tpGroup
                })
                if(!dis && e.disabled == 1){
                    dis = true;
                }
            }
            //如果没有设置默认值，则选择第一条作为默认值
            if(!dis && this.form.ticketSettings.length>0 && this.datas && this.datas.length > 0){
                let ts = this.datas.find(d => d.xgroup == this.tpGroup);
                if(ts){
                    ts.disabled = 1;
                }   
            }

            //构建附加重量
            for(let i=0; i<this.form.attachWeights.length; i++){
                let attach = this.form.attachWeights[i];
                let key = this.awGroup+i;

                let pweight = attach.addedPercentWeight;
                let type = attach.addedWeightType;
                let weight = attach.addedWeight;
                if(type == 0){
                    pweight = 0;
                }else{
                    weight = 0;
                    if(pweight){
                        pweight = (pweight/100).toFixed(2);
                    }
                }

                datas.push({
                    id: attach.id,
                    disabled: attach.disabled,
                    xkey: key,
                    xlabel: attach.xlabel,
                    owner: attach.owner?attach.owner:null,
                    xgroup: this.awGroup,
                    xvalue: JSON.stringify({
                        addedWeightType: type,
                        addedWeight: weight,
                        addedPercentWeight: pweight
                    }),
                });
            }

            //构建材积计算
            for(let i=0; i<this.form.volumns.length; i++){
                let volumn = this.form.volumns[i];
                let key = this.vfGroup;

                let volumn1 = volumn.volumn1;
                let volumn2 = volumn.volumn2;
                let volumn3 = volumn.volumn3;

                datas.push({
                    id: volumn.id,
                    xkey: key,
                    owner: volumn.owner?volumn.owner:null,
                    xgroup: key,
                    xvalue: JSON.stringify({
                        volumn1: volumn1,
                        volumn2: volumn2,
                        volumn3: volumn3
                    }),
                });
            }

            //构建打印提醒设置
            for(let i=0; i<this.form.printWarns.length; i++){
                let warn = this.form.printWarns[i];
                let key = this.pintGroup;
                datas.push({
                    id: warn.id,
                    xkey: key,
                    owner: warn.owner?warn.owner:null,
                    xgroup: this.pintGroup,
                    // xvalue: warn.xvalue,
                    xvalue: JSON.stringify({
                        printWarn:warn.printWarn?warn.printWarn:0,
                        printType:warn.printType?warn.printType:false,
                        pkgRules:this.pkgRules,
                    }),
                    xtype:1
                });
            }

            for(let i=0; i<this.form.shipAddress.length; i++){
                let addr = this.form.shipAddress[i];
                let key = this.addressGroup;
                datas.push({
                    id: addr.id,
                    xkey: key,
                    owner: addr.owner?addr.owner:null,
                    xgroup: this.addressGroup,
                    xvalue: addr.xvalue.trim(),
                    xtype:1
                });
            }

            //构建航空报表
            if(this.hasPermission('stock:downloadHangkong')){
                let hk = this.form.hks[0];
                let hangkongs = this.$refs.hangkongRef.selection;
                if(hangkongs.length==0){
                    this.$message(this.msg('航空字段必须选中一个'));
                    return;
                }
                this.hangkongs.forEach(e =>{
                    e.show = false;
                    hangkongs.forEach(f => {
                        if(e.key == f.key){
                            e.show = true;
                        }
                    })
                })
                let hkvalue = JSON.stringify(this.hangkongs);
                datas.push({
                    id: hk.id?hk.id:null,
                    xkey: this.hangkongGroup,
                    owner: hk.owner?hk.owner:null,
                    xgroup: this.hangkongGroup,
                    xvalue: hkvalue,
                    xtype:1
                });
            }

            //构建清关报表
            if(this.hasPermission('stock:downloadQingguan')){
                let qg = this.form.qgs[0];
                let qingguans = this.$refs.qingguanRef.selection;
                if(qingguans.length==0){
                    this.$message(this.msg('清关字段必须选中一个'));
                    return;
                }
                this.qingguans.forEach(e =>{
                    e.show = false;
                    qingguans.forEach(f => {
                        if(e.key == f.key){
                            e.show = true;
                        }
                    })
                })
                let qgvalue = JSON.stringify(this.qingguans);
                datas.push({
                    id: qg.id?qg.id:null,
                    xkey: this.qingguanGroup,
                    owner: qg.owner?qg.owner:null,
                    xgroup: this.qingguanGroup,
                    xvalue: qgvalue,
                    xtype:1
                });
            }
            //构建派件表
            if(this.hasPermission('stock:downloadHeimao')){
                let pj = this.form.pjs[0];
                let paijians = this.$refs.paijianRef.selection;
                if(paijians.length==0){
                    this.$message(this.msg('派件字段必须选中一个'));
                    return;
                }
                this.paijians.forEach(e =>{
                    e.show = false;
                    paijians.forEach(f => {
                        if(e.key == f.key){
                            e.show = true;
                        }
                    })
                })
                let pjvalue = JSON.stringify(this.paijians);
                datas.push({
                    id: pj.id?pj.id:null,
                    xkey: this.paijianGroup,
                    owner: pj.owner?pj.owner:null,
                    xgroup: this.paijianGroup,
                    xvalue: pjvalue,
                    xtype:1
                });
            }

            //构建新竹派件表
            if(this.hasPermission('stock:downloadHeimao')){
                let xz = this.form.xzs[0];
                let xinzhus = this.$refs.xinzhuRef.selection;
                if(xinzhus.length==0){
                    this.$message(this.msg('新竹派件字段必须选中一个'));
                    return;
                }
                this.xinzhus.forEach(e =>{
                    e.show = false;
                    xinzhus.forEach(f => {
                        if(e.key == f.key){
                            e.show = true;
                        }
                    })
                })
                let xzvalue = JSON.stringify(this.xinzhus);
                datas.push({
                    id: xz.id?xz.id:null,
                    xkey: this.xinzhuGroup,
                    owner: xz.owner?xz.owner:null,
                    xgroup: this.xinzhuGroup,
                    xvalue: xzvalue,
                    xtype:1
                });
            }

            //构建个人安全密码设置
            if (this.form.strongPwd.length > 0) {
                var pwd = this.form.strongPwd[0].xvalue;
                if (pwd) {
                  if (pwd.length < 4) {
                    this.$message.error(this.msg("个人安全密码太短！"))
                    return;
                  }
                  var reg = this.$kit.strongAuthData.reg;
                  if (reg.test(pwd)) {
                    this.$message.error(this.msg("个人安全密码包含无效字符！"))
                    return;
                  }
                  datas.push(this.form.strongPwd[0]);
                }
            }
            var _this = this;
            var pwdSetting = datas.filter(a => a.xkey == 'strongPwd');
            if (pwdSetting.length > 0 && pwdSetting[0].hasValue) {
              _this.$kit.strongAuthData.closeEvent = function (type) {
                _this.strongPwdVisiable = false;
                if (type) {
                  _this.$axios.post(_this.$kit.api.user.settingUpdate, datas).then((result) => {
                    _this.$message(result.msg)
                  });
                }
              };
              _this.strongPwdVisiable = true;
            }else{
              _this.$axios.post(_this.$kit.api.user.settingUpdate, datas).then((result) => {
                var tmp = _this.form.strongPwd[0];
                tmp.hasValue = true;
                _this.$set(_this.form.strongPwd, 0, tmp);
                _this.$message(result.msg)
              });
            }
        },
        setDefault(idx, key){ //设置默认值
            let arr = this.form[key];
            if(arr.length>0){

                let disabled = arr[idx].disabled;
                if(disabled == 1){
                    for(let i = 0; i<arr.length; i++){
                        let item = arr[i];
                        if(i!=idx){
                            item.disabled = 0;
                        }
                    }
                }
            }
        }

    }
}
</script>

<style lang="scss" scoped>
.pts-checkbox{
    margin: 5px 15px 5px 0px !important;
}
.my-tab{
    margin: 10px 15px;
}
.page-tpl /deep/ .print-wrap{
    margin-left: -50px;
    transform: scale(0.75); 
    width: 360px;
}
</style>