<!-- 个人登录日志-->
<template>
    <div>
        <div class="table-wrap">
            <vxe-grid ref="dataTable"
                resizable border
                export-config
                show-overflow highlight-hover-row
                highlight-current-row highlight-hover-column highlight-current-column
                :data="loginHistorys"
                :columns="columns"
            ></vxe-grid>
            <pagenator
                @page-change="handlerPageChange"
                :query="query"
            >
            </pagenator>
        </div>
    </div>
</template>

<script>

import Pagenator from "../../components/Pagination.vue";
export default {
    name: 'LoginHistory',
    components: {
        Pagenator:Pagenator,
    },
    mounted(){
        this.getHistorys();
    },
    data(){
        return {
            query:{
                ip:null,
                Date: [], 
                length: 100, //每页显示的长度
                start: 0, //起始数量
                pageTotal:0,//总记录数
                pageIndex: 1,
            },

            showQuery: false,
            columns: [
                { type: 'index', title: '序号', minWidth: 20 },
                {field: "created", title: this.msg("登录时间"), minWidth: 120
                },
                {field: "ip", title: this.msg("IP地址"), minWidth: 120
                },
            ],
            loginHistorys: [],      //权限列表
            curr: null,
            currHist:null,
        }
    },
    computed: {
        isMinScreen: {  //是否小屏幕，浏览器
            get() {
                var is = this.$store.state.isMinScreen;
                return is;
            }
        },
        docHeight: {  //表格的高度
            get() {
                var height =  this.$store.state.docHeight;
                return height - 220;
            }
        },
        routeTab: {
            get(){
                try{
                    return this.$store.state.activeMenus[this.$route.path]
                }catch{
                    return null;
                }
            }
        }
    },
    methods:{
        getHistorys(refresh){
            var _this = this;
            if(refresh){
                this.query.pageIndex = 1;
                this.query.start = 0;
            }

            var params = this.$kit.rebuildQuery(this.query);
            this.$axios.post(this.$kit.api.history.loginlist, params)
                .then((result) => {
                    if(result.status){
                        _this.loginHistorys = result.data.list;
                        _this.query.pageTotal = result.data.total;
                    }
                });
        },
        //分页数据发生改变，回调事件：info[0]: 每页显示的数量，info[1]: 起始页
        handlerPageChange(info){
            this.query.length = info[0];
            this.query.start = info[1];
            this.getHistorys();
        },
    }
    
}
</script>

<style scoped lang="scss">

</style>
