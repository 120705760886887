<!--货单打印模板预览-->
<template>
  <div
    class="print-box"
  >
    <div class="print-wrap">
      <div class="invoice-box">
        <div class="invoice-header">
          <div
            class="barcode-warp"
          >
            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAAAoCAIAAAD2TmbPAAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAtUlEQVR4nO3RQQ6AIAwAQer//4wHIiFNi/fNzkkNFHVjfOacEbEu1pN0u5XL9pC07Jyc1lw2rttzQjr63FvOLAemQ/ey7lXLo7uDusm/E7o3uXxRub38Oc8QmoHhDAxnYDgDwxkYzsBwBoYzMJyB4QwMZ2A4A8MZGM7AcAaGMzCcgeEMDGdgOAPDGRjOwHAGhjMwnIHhDAxnYDgDwxkYzsBwBoYzMJyB4QwMZ2A4A8MZGM7AcC8t7VFQ8H46DwAAAABJRU5ErkJggg==" />
            <div>200846541561616</div>
          </div>
        </div>
        <div class="invoice-header1">
            <div
              class="company"
            >7-11</div>
            <div style="font-size: 30px; font-weight:bold;">1616</div>
          </div>
        <div class="dotted-line" v-show="ticketSetting.invoice && ticketSetting.invoiceHead"></div>
        <div class="dotted-line" v-show="ticketSetting.invoiceTable"></div>
        <div v-show="ticketSetting.detailRemark1" class="invoice-remark">報單備註</div>
        <div v-show="ticketSetting.detailRemark2" class="invoice-remark">貨單備註</div>
        <div v-show="ticketSetting.note" class="invoice-remark">訂單備註</div>
        <div v-show="ticketSetting.shopSellerMsg" class="invoice-remark">留言</div>
        <div v-show="ticketSetting.pkgType" class="invoice-remark">包裹類型</div>
        <div v-show="ticketSetting.shopName" class="invoice-remark">店铺名</div>
        <div v-show="ticketSetting.ownerName" class="invoice-remark">归属人</div>
        <div v-show="ticketSetting.receiver" class="invoice-remark">收件人</div>
        <div v-show="ticketSetting.expGoodType" class="invoice-remark">货物类型</div>
        <div v-show="ticketSetting.orderPlatform" class="invoice-remark">订单卖场</div>
        <div class="xtable-wrap" v-show="ticketSetting.detail">
          <table class="orders">
            <thead>
              <tr>
                <th v-show="ticketSetting.detail && ticketSetting.detailName">貨物品名</th>
                <th v-show="ticketSetting.detail && ticketSetting.detailCode">貨物編號</th>
                <th v-show="ticketSetting.detail && ticketSetting.detailShelfCode">貨物架位</th>
              </tr>
            </thead>
            <tbody>
              <!--[快递单号，货架号，件数，品名，商品名，规格]-->
              <template>
                <tr>
                  <td class="td-qty" v-show="ticketSetting.detail && ticketSetting.detailName">貨物品名</td>
                  <td class="td-code" v-show="ticketSetting.detail && ticketSetting.detailCode">
                    <span>123456</span>
                  </td>
                  <td class="td-shelf" v-show="ticketSetting.detail && ticketSetting.detailShelfCode">
                    <span>123456</span>
                  </td>
                </tr>
                <tr v-show="ticketSetting.detail && ticketSetting.detailTitle">
                  <td colspan="3" class="td-name">
                    <span>描述：名称</span>
                  </td>
                </tr>
                <tr v-show="ticketSetting.detail && ticketSetting.detailSpec">
                  <td colspan="3" class="td-spec">
                    <span>規格：规格</span><span>&nbsp;&nbsp;&nbsp;&nbsp;<strong>||&nbsp;&nbsp;&nbsp;&nbsp;件數</strong>：12</span><span v-show="ticketSetting.detailStoreType">&nbsp;&nbsp;&nbsp;&nbsp;现货仓储</span>
                  </td>
                </tr>
                <tr v-show="ticketSetting.detail && ticketSetting.detailSku">
                  <td colspan="3" class="td-name">
                    <span>SKU：sku</span>
                  </td>
                </tr>
                <tr v-show="ticketSetting.detail && ticketSetting.detailSku">
                  <td colspan="3" class="td-name">
                    <span>货号：货号</span>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <!--end orders-->
        <div class="invoice-footer">
          <span v-show="ticketSetting.detailPkg">0</span>
          <span v-show="ticketSetting.detailDate" class="date">2020-01-01
             <!-- <span style="display:inline-block; margin-left:5px; font-size:18px; font-weight:bold;">已驗視</span> -->
          </span>
        </div>
      </div>
      <!--end invoice-box-->
    </div>
  </div>
</template>

<script>
export default {
  name: "Ticket",
  props: {
    ticketSetting: Object,
  },
};
</script>

<style scoped lang="scss">
.print-box {
  width: 100%;
  margin-top: 10px;
}
.print-wrap {
  margin: 0px auto;
  width: 400px;
}
.invoice-header {
  position: relative;
  text-align: center;
  line-height: 1;
  padding-bottom: 10px;
}
.invoice-header1{
  display:flex; 
  justify-content: space-between;
  align-items: center;
}
.invoice-header1 .company {
  font-size: 30px;
  line-height: 1;
}
.invoice-remark {
  font-size: 14px;
  color: #333;
}
.dotted-line {
  border-bottom: 2px dashed #000;
}
.print-wrap h3 {
  margin: 6px 0px;
  text-align: center;
  font-size: 16px;
}
.line-row {
  display: flex;
  font-size: 13px;
  color: #333;
  flex-wrap: nowrap;
}
.line-row .inline {
  flex: 0 0 50%;
}

.xline {
  font-size: 13px;
  display: flex;
  color: #333;
  flex-wrap: nowrap;
}

.xtable-wrap th {
  font-size: 14px;
  color: #333;
  border-left: 1px solid #bbb;
  border-top: 1px solid #bbb;
}
.xtable-wrap table {
  border-bottom: 1px solid #bbb;
  border-right: 1px solid #bbb;
  width: 100%;
  border-spacing: 0px;
  margin: 10px 0px;
}
.xtable-wrap td {
  border-left: 1px solid #bbb;
  border-top: 1px solid #bbb;
  font-size: 13px;
  padding-left: 3px;
  padding-right: 3px;
  color: #333;
}

.invoice-footer {
  font-size: 13px;
  color: #333;
  display: flex;
  justify-content: space-between;
}
.td-spec {
  border-bottom: 2px solid #666;
}
.next-page {
  page-break-before: always;
}
.exp-page {
  text-align: center;
}
.page711 {
  width: 340px;
}
.page-normal {
  width: 400px;
}

.tag-wrap {
  text-align: center;
  line-height: 1;
  font-size: 13px;
  color: #333;
}
.tag-owner {
  font-size: 16px;
  margin: 6px 0px;
}
.tag-detail {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
}
.tag-date {
  text-align: right;
  margin-top: 10px;
  margin-bottom: 10px;
}
.product-img{
  width:100%;
}
</style>
